var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content-register"},[_c('div',{staticClass:"content-register-itens container"},[_c('router-link',{staticClass:"content-register-itens--logo",attrs:{"tag":"a","to":{name: 'login'}}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/logo-grupo-nabeiro.png"),"alt":"logo grupo nabeiro","title":"logo grupo nabeiro"}})]),_c('form',{ref:"formRegister",staticClass:"content-register-itens-form",on:{"submit":function($event){$event.preventDefault();return _vm.onRegister.apply(null, arguments)}}},[_vm._m(0),_c('div',{staticClass:"content-register-itens-form-inputs"},[_c('div',{staticClass:"row"},[_c('input',{attrs:{"type":"hidden","name":"form_origin","value":"create_account_one"}}),_c('input',{attrs:{"type":"hidden","value":"2","name":"owner_id"}}),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"content-input"},[_c('input',{attrs:{"type":"text","name":"code","value":"","placeholder":"ID colaborador","required":""}}),_c('button',{staticClass:"btn--help",attrs:{"type":"button"},on:{"click":function($event){return _vm.$bvModal.show('modal-collaborator')}}},[_c('p',[_vm._v("Ajuda")]),_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/icon-help.png"),"alt":"icon help","title":"icon help"}})])])]),_vm._m(1),_vm._m(2)]),_vm._m(3),_c('div',{staticClass:"row"},[_c('Terms')],1)]),_vm._m(4),_c('div',{staticClass:"content-register-itens-form-btns"},[_c('button',{staticClass:"btn--black",attrs:{"type":"submit"}},[_c('p',[_vm._v("Criar conta")]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"8","height":"9","viewBox":"0 0 8 9"}},[_c('path',{attrs:{"d":"M4.5,0,9,8H0Z","transform":"translate(8) rotate(90)"}})])]),_c('router-link',{staticClass:"btn--link",attrs:{"tag":"a","to":{name: 'login'}}},[_vm._v("Voltar")])],1),_vm._m(5)])],1),_c('ModalCollaborator')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-register-itens-form--title"},[_c('h1',[_vm._v("Criar conta")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"content-input"},[_c('input',{attrs:{"type":"text","name":"name","value":"","placeholder":"Primeiro nome","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"content-input"},[_c('input',{attrs:{"type":"text","name":"nickname","value":"","placeholder":"Último nome","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"content-input"},[_c('input',{attrs:{"type":"password","name":"password","value":"","placeholder":"Password","required":""}})])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"content-input"},[_c('input',{attrs:{"type":"password","name":"password_confirmation","value":"","placeholder":"Confirmar password","required":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recaptcha"},[_c('div',{staticClass:"g-recaptcha",attrs:{"data-sitekey":"6LehbsIlAAAAAKxpODmzjtQIuwHatg8nftbeutIb"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"content-register-itens-form--footer"},[_c('p',[_vm._v("Caso tenhas dificuldades em fazer o registo envia um email para: "),_c('br'),_c('a',{attrs:{"href":"mailto:clientesamigos@gruponabeiro.com","target":"_blank"}},[_vm._v("clientesamigos@gruponabeiro."),_c('wbr'),_vm._v("com")]),_vm._v(".")])])])])
}]

export { render, staticRenderFns }