<template>
  <section class="content-register">
    <div class="content-register-itens container">
      <router-link tag="a" :to="{name: 'login'}" class="content-register-itens--logo">
        <img class="img-fluid" src="@/assets/img/logo-grupo-nabeiro.png" alt="logo grupo nabeiro" title="logo grupo nabeiro"/>
      </router-link>
      <form ref="formRegister" @submit.prevent="onRegister" class="content-register-itens-form">
        <div class="content-register-itens-form--title">
          <h1>Criar conta</h1>
        </div>
        <div class="content-register-itens-form-inputs">
          <div class="row">
            <input type="hidden" name="form_origin" value="create_account_one">
            <!-- hidden input owner_id 2 == DELTA GAMIFICATION PT -->
            <input type="hidden" value="2" name="owner_id" />
            <div class="col-lg-6">
              <div class="content-input">
                <input type="text" name="code" value="" placeholder="ID colaborador" required/>
                <button type="button" class="btn--help" @click="$bvModal.show('modal-collaborator')">
                  <p>Ajuda</p>
                  <img class="img-fluid" src="@/assets/img/icon-help.png" alt="icon help" title="icon help"/>
                </button>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="content-input">
                <input type="text" name="name" value="" placeholder="Primeiro nome" required/>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="content-input">
                <input type="text" name="nickname" value="" placeholder="Último nome" required/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="content-input">
                <input type="password" name="password" value="" placeholder="Password" required/>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content-input">
                <input type="password" name="password_confirmation" value="" placeholder="Confirmar password" required/>
              </div>
            </div>
          </div>
          <div class="row">
           <Terms/>
          </div>
        </div>
        <div class="recaptcha">
          <div class="g-recaptcha" data-sitekey="6LehbsIlAAAAAKxpODmzjtQIuwHatg8nftbeutIb"></div>
        </div>
        <div class="content-register-itens-form-btns">
          <button type="submit" class="btn--black">
            <p>Criar conta</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9"><path d="M4.5,0,9,8H0Z" transform="translate(8) rotate(90)"/></svg>
          </button>
          <router-link class="btn--link" tag="a" :to="{name: 'login'}">Voltar</router-link>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="content-register-itens-form--footer">
              <p>Caso tenhas dificuldades em fazer o registo envia um email para:
	              <br><a href="mailto:clientesamigos@gruponabeiro.com" target="_blank">clientesamigos@gruponabeiro.<wbr>com</a>.</p>
            </div>
          </div>
        </div>
      </form>
    </div>
    <ModalCollaborator/>
  </section>
</template>

<script>
import Terms from '@/components/Terms';
import ModalCollaborator from '@/components/Modals/ModalCollaborator';
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';

export default {
  data() {
    return {
      code: '',
    };
  },
  components: {
    Terms,
    ModalCollaborator
  },
  computed: {
    ...mapGetters('App', ['recaptchaKey']),
    ...mapGetters('User', ['user']),
  },
  methods:{
    ...mapActions('App', ['loading']),    
    ...mapActions('App', ['loading']),
    ...mapActions('User', ['setUser', 'setToken']),    
    async onRegister(el){            
        let formData = new FormData(this.$refs.formRegister)
        this.loading(true);
        await axios({
            method: 'POST',
            url: '/api/v1/campaign/user/register',
            data: formData
        }).then(response => response.data)
        .then((data) => {                
            this.loading(false);
            this.$notify({                    
                type: "success",
                text: data.message,
                duration: 600
            });
            localStorage.setItem('code', data.data.code);
            this.code = localStorage.getItem('code');
            this.setUser(data.data);
            this.$router.push({
              name: 'create-account'
            })
        })
        .catch((error) => {
            this.loading(false);
            this.$notify({                    
                type: "warn",
                text: error.response.data.message,
                duration: 3000,
            });
        })
    },
  },
}
</script>

<style lang="scss">
@import './index.scss';
.btn--help {
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: 0;
  border: 0;
  p {
    font-size: 8px;
    font-weight: var(--font-weight-regular);
    color: var(--black);
    text-transform: uppercase;
    margin-right: 5px;
    margin-bottom: 0;
  }
}
</style>
